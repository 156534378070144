
.container {
  container-type: inline-size;
}

.row, 
.contentColumn, 
.balanceAndBrokerRow, 
.sidebarColumn,
.watchlistColumn {
  gap: 4px;
  @container (min-width: 1024px) {
    gap: 16px;
  }
}

.row {
  padding: 4px;
  @container (min-width: 1024px) {
    padding: 16px;
  }
}

.row {
  display: flex;
  flex-direction: column;
}

.contentColumn {
  display: flex; 
  flex-direction: column; 
  width: 100%;
}

.balanceAndBrokerRow {
  display: flex;
  flex-direction: row;
  > * {
    flex: 1;
  }
}

.sidebarColumn {
  display: flex; 
  flex-direction: column; 
  width: 100%;
}

.watchlistColumn {
  display: none;
}

.watchlistRow {
  // position: sticky; 
  // top: calc(64px + 4px);
  // z-index: 999;
}

@container (min-width: 940px) {

  .watchlistRow {
    display: none;
  }

  .row {
    flex-direction: row;
  }
  
  .contentColumn {
    flex: 1 1 60%;
  }
  
  .sidebarColumn {
    flex: 0 1 350px;
  }

  .watchlistColumn {
    display: unset;
    flex: 0 0 100px;
    position: sticky;
    top: calc(64px + 8px); 
    z-index: 999; 
    height: calc(100vh - 64px - 8px - 8px);
    & :global(.ant-card) {
      display: flex;
      flex-direction: column;
      overflow: auto;
    }
    // background: green;
    > * {
      height: 100%;
    }
  }
}

@container (min-width: 940px) and (max-width: 1024px) {
  .watchlistColumn :global(.ant-card-body) {
    // padding-left: 8px;
    // padding-right: 8px;
  }
}

@container (min-width: 1024px) {
  .sidebarColumn {
    flex: 0 1 450px;
  }

  .watchlistColumn {
    top: calc(64px + 16px);
    height: calc(100vh - 64px - 16px - 8px);
  }
}