.layout {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.innerLayout {
  min-height: unset; 
  height: auto; 
  display: flex; 
  flex-direction: row; 
  gap: 8px;
  align-items: stretch;
  min-height: 81vh;
}

.contentWrapper {
  flex-grow: 1;
}

.sidebarWrapper {
  display: none;
  &:global(.ant-card .ant-card-body) {
    padding: 0.25rem;
  }
  :global(.ant-menu) {
    min-width: 250px;
    border-radius: 4px;
    height: 100%;
    border-inline-end: none !important;
  }
}

@media screen and (min-width: 992px) {
  .sidebarWrapper {
    display: unset;
  }
}

.mobileMenuLayout .contentWrapper {
  display: none;
}

.mobileMenuLayout .sidebarWrapper {
  display: block;
  width: 100%;
}
