.sidebar {
  position: fixed !important;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  overflow: auto;
  height: 100vh;
}

.sidebar {
  &:global(.ant-layout-sider-collapsed) {
    .logo .logoText {
      display: none;
    }
    .logo {
      justify-content: center;
    }
  }
}

.logo {
  padding: 16px 8px;
  gap: 8px;
  display: flex; 
  justify-content: flex-start; 
  align-items: center;
  .logoIcon {
    flex-grow: 0;
    flex-shrink: 0;
  }
  .logoText {
    flex-grow: 0;
    flex-shrink: 1;
  }
}