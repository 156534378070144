.messageItemWrapper {
  display: flex;
  flex-direction: row; 
  gap: 8px;
  margin-bottom: 16px;
  width: 100%;
}

.messageItemWrapper.right {
  justify-content: flex-end;
}

.messageItemWrapper.left {
  justify-content: flex-start;
}

.messageWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 80%;
  @media screen and (min-width: 768px) {
    max-width: 60%;
  }
      // maxWidth: '80%',
    // [`@media screen (min-width: ${token.screenLGMin}px)`]: {
    //   maxWidth: '60%',
    // },

}

.messageWrapper.right {
  align-items: flex-end;
}

.messageWrapper.left {
  align-items: flex-start;
}