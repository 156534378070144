.card {
  container-type: inline-size;
}

.row {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @container (min-width: 300px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.balanceColumn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
  .toggleButton {
    margin: 0;
  }
  .balanceColumnInner {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    .coninsLabel {
      margin: 0;
    }
    .balance {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .total {
        margin: 0;
        font-weight: 400;
      }
      .breakdown {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        .breakdownItem {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

.buttonColumn {
  .rechargeButton {
    margin: 0;
  }
}

