.card {
  & :global(.ant-card-body) {
    padding: 16px !important;
  }
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media screen and (min-width: 992px) {
  .cardContent {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
}