body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.small-container {
  width: 100%;
  max-width: 100%;
  padding: 0 16px;
}

@media (min-width: 576px) {
  .small-container {
    max-width: 565px;
    margin: 0 auto;
    padding: 0 0;
  }
}

@media (min-width: 768px) {
  .small-container {
    max-width: 700px;
  }
}

@media (min-width: 992px) {
  .small-container {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .small-container {
    max-width: 900px;
  }
}

.ant-layout {
  min-height: 100vh;
}

.ant-form-item-margin-offset {
  margin: 0 !important;
}

.ant-card {
  border: none;
}

.ant-tag {
  font-size: 0.8rem;
  font-weight: bold;
  padding: 2px 8px;
  letter-spacing: 1px;
}

.ant-tag-small {
  font-size: 0.7rem;
  letter-spacing: 0.5px;
}

::-webkit-scrollbar {
  width: 8px; /* Mostly for vertical scrollbars */
  height: 8px; /* Mostly for horizontal scrollbars */
}

::-webkit-scrollbar-thumb { 
  /* Foreground */
  background: var(--scrollbar-color);
  border-radius: 12px;
}

::-webkit-scrollbar-track { 
  /* Background */
  background: var(--scrollbar-background);
  border-radius: 12px;
}

:root {
  --premium-yellow: #ffe300;
  --premium-yellow-foreground: black;
  --premium-yellow-light: #ffec54;
  --premium-yellow-light-foreground: black;
}