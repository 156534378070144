.mainHeader {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding-inline: 16px !important;
}

.mainNav {
  display: flex; 
  justify-content: space-between;
  align-items: center;
}

.mainMenu {
  display: flex;
  gap: 8px;
  align-items: center;
}