
.watchlist {
  // display: flex; 
  overflow: auto; 
  // align-items: center;
  // -ms-overflow-style: none;
  // scrollbar-width: none;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  .group .removeButton {
    opacity: 0.8;
    span {
      font-size: 0.8rem;
    }
  }
  .group {
    display: flex;
  }
  .title, .empty {
    text-align: center;
    flex-shrink: 0;
  }
  &.vertical {
    & .selectListButton {
      max-width: 100px;
      white-space: break-spaces;
      word-wrap: break-word;
      height: auto;
    }
    width: 100%;
    // background-color: red;
    overflow: auto;
    padding: 20px 10px;
    .title {
      margin-bottom: 20px;
    }
    & > * {
      width: 100% !important;
      margin-bottom: 8px;
    }
    // gap: 8px;
    // flex-direction: column;
    // align-items: stretch;
    // text-align: center;
    .group > :first-child {
      flex-grow: 1;
    }
  }
  &.horizontal {
    white-space: nowrap;
    overflow: auto;
    padding: 20px 20px;
    .title {
      margin-right: 20px;
    }
    & > * {
      display: inline-block;
      margin-right: 8px;
    }
    // gap: 16px;
    // flex-direction: row;
  }
}